<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("ORGANIZATIONS.EDIT_ORGANIZATION") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <organization-form
              :loading="loading"
              :organizationData="organization"
              :formErrors="formErrors"
              @organizationSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationForm from "./partials/OrganizationForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultOrganization from "./defaultOrganization";

export default {
  layout: "DashboardLayout",

  components: { OrganizationForm },

  mixins: [alertLeave],

  data() {
    return {
      organization: cloneDeep(defaultOrganization),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("organizations/get", id);
        this.organization = this.$store.getters["organizations/organization"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Organizations" });
    },

    viewOrganization() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Organization",
        params: { id: this.organization.id },
      });
    },

    async handleSubmit(organization) {
      this.loading = true;
      const organizationData = cloneDeep(organization);
      if (this.$currentUserIsLevelReseller()) {
        organizationData.reseller.id = this.$currentUserResellerId();
      }

      try {
        await this.$store.dispatch("organizations/update", organizationData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_UPDATED"),
        });
        this.viewOrganization();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
